<template>
  <div class="pcadd">
    <div class="crumbs">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>
          <i class="el-icon-menu"></i> 客户端桌面推送
          <i class="el-icon-arrow-right"></i>
          {{$route.name =='pcDesktopAdd' ?'添加':'修改'}}
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="container">
      <el-form ref="formData" label-width="140px">
        <el-row>
          <el-col :span="6">
            <el-form-item label="客户端类型：" required>
              <el-select v-model="formData.clientId" placeholder="请选择" class="handle-select mr20">
                <el-option
                  :key="item.id"
                  v-for="(item) in channelDatas"
                  :label="item.description"
                  :value="item.clientId"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="消息标题：" required>
              <el-input v-model="formData.title" placeholder="请输入标题" maxlength="30"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="消息正文：" required>
              <el-input
                type="text"
                placeholder="请输入内容"
                v-model="formData.content"
                maxlength="60"
                show-word-limit
                style="padding-right:50px;"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="配图：" required>
          <el-upload
            class="up-btn"
            :http-request="uploadCover"
            action="String"
            :beforeUpload="beforeRemove"
            :show-file-list="false"
          >
            <el-button type="primary">点击上传</el-button>
          </el-upload>
          <div class="person-img">
            <el-image class="pre" :src="formData.banner" :preview-src-list="[formData.banner]"></el-image>
          </div>
        </el-form-item>
        <el-row>
          <el-col :span="6">
            <el-form-item label="行动按钮文案：" required>
              <el-input v-model="formData.text" placeholder="请输入" maxlength="10"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            <el-form-item label="推送国家：" required>
              <el-select
                v-model="formData.country"
                multiple
                placeholder="请选择"
                @change="countryChange"
              >
                <el-option
                  v-for="(item) in  countryFullList"
                  :key="item.country_id"
                  :label="item.country_name_cn"
                  :value="item.country_name_en"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="用户身份：" required>
              <el-select v-model="formData.userType" placeholder="请选择" class="handle-select mr20">
                <el-option
                  :key="item.id"
                  v-for="(item) in userlevelArr"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="推送时间：" required>
          <el-date-picker
            v-model="times"
            style="width: 240px"
            @change="changeTimer"
            format="YYYY-MM-DD"
            :default-value="defaultDate"
            :disabled-date="disabledDate"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="每日弹出时间点：" required>
          <el-time-picker format="HH:mm:ss" v-model="date1" placeholder="选择日期" class="mr20"></el-time-picker>
          <el-time-picker v-model="date2" placeholder="选择日期" class="mr20"></el-time-picker>
          <el-time-picker v-model="date3" placeholder="选择日期" class="mr20"></el-time-picker>
          <el-time-picker v-model="date4" placeholder="选择日期" class="mr20"></el-time-picker>
          <el-time-picker v-model="date5" placeholder="选择日期" class="mr20"></el-time-picker>
        </el-form-item>

        <el-row>
          <el-col :span="6">
            <el-form-item label="跳转链接：" required>
              <el-input v-model="formData.jumpUrl" placeholder="请输入网址"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div class="view">
        <div class="title">
          <img src="../../assets/img/pclogo.png" alt />
          我是客户端名称
          <i class="el-icon-close"></i>
        </div>
        <img :src="formData.banner" alt class="banner" />
        <div class="p1 ell">{{formData.title}}</div>
        <div class="p2 ell2">{{formData.content}}</div>
        <div class="b">{{formData.text}}</div>
      </div>
      <el-button
        type="primary"
        icon="el-icon-s-promotion"
        :disabled="loading"
        @click="pushFn"
      >{{$route.name =='pcDesktopAdd' ?'发布':'修改'}}</el-button>
    </div>
  </div>
</template>

<script>
import {
  clientConfig,
  pcUpdate,
  getUploadMh,
  pcGetById
  //   seoLabelUpdate,
  //   seoLabelBatch,
  //   seoLabelList,
  //   seoLabelOption
} from "../../api/index";
import { countryFullList } from "../../assets/js/common";
const all = {
  country_name_cn: "全球",
  country_name_en: "AnyCountry",
  country_id: 999999
};
export default {
  name: "pcAdd",
  data() {
    return {
      loading: false,
      defaultDate: [],
      formData: {
        clientId: "", // 客户端ID
        title: "",
        content: "",
        text: "", // 文案
        country: [], // 推送国家
        userType: "", // 用户身份
        popupTime: [], // 推动时间
        beginTime: "", // 推送开始时间
        endTime: "", // 推送结束时间
        jumpUrl: "",
        banner: ""
      },
      textarea: "",
      sourceUrl: "",
      date1: "",
      date2: "",
      date3: "",
      date4: "",
      date5: "",
      userlevelArr: [
        {
          id: 1,
          name: "全部用户"
        },
        {
          id: 3,
          name: "付费用户"
        },
        {
          id: 2,
          name: "免费用户"
        }
      ],

      user: "",
      times: "",
      country: [],
      countryTag: [],
      clientId: "",
      channelDatas: [],
      title: "",
      countryFullList: [all, ...countryFullList],
      dialogTitle: "添加标签",
      startTime: "",
      endTime: ""
    };
  },
  created() {
    this.channelArray(); // 渠道
    const id = this.$route.query.id;
    if (id) {
      this.getData(id);
    }
  },
  methods: {
    disabledDate(time) {
      return time.getTime() < Date.now() - 8.64e7; // 8.64e7 毫秒数代表一天
    },
    async getData(id) {
      try {
        const { code, data } = await pcGetById(id);
        if (code == 0) {
          console.log("data", data);
          // let countryNumbers = [];
          // if (data.country.length > 0) {
          //   countryNumbers = data.country.map(Number);
          // }
          //  times: [new Date(2000, 10, 10), new Date(2000, 10, 11)],
          if (data.beginTime && data.endTime) {
            let beginTimeArr = data.beginTime
              .split("T")[0]
              .split("-")
              .map(Number);
            beginTimeArr[1] -= 1;
            let endTimeArr = data.endTime
              .split("T")[0]
              .split("-")
              .map(Number);
            endTimeArr[1] -= 1;

            this.times = [new Date(...beginTimeArr), new Date(...endTimeArr)];
          }
          // new Date(0, 0, 0, '01', 33, 0)
          if (data.popupTime.length > 0) {
            data.popupTime.forEach((element, index) => {
              const res = element.split(":").map(Number);
              switch (index + 1) {
                case 1:
                  this.date1 = new Date(0, 0, 0, ...res);

                  break;
                case 2:
                  this.date2 = new Date(0, 0, 0, ...res);

                  break;
                case 3:
                  this.date3 = new Date(0, 0, 0, ...res);

                  break;
                case 4:
                  this.date4 = new Date(0, 0, 0, ...res);

                  break;
                case 5:
                  this.date5 = new Date(0, 0, 0, ...res);

                  break;

                default:
                  break;
              }
            });
          }
          this.formData = Object.assign(
            {
              clientId: "", // 客户端ID
              title: "",
              content: "",
              text: "", // 文案
              country: [], // 推送国家
              userType: "", // 用户身份
              popupTime: [], // 推动时间
              beginTime: "", // 推送开始时间
              endTime: "", // 推送结束时间
              jumpUrl: "",
              banner: ""
            },
            {
              id: data.id,
              ...data,
              country: data.country
              // clientId: data.clientId, // 客户端ID
              // title: data.title,
              // content: data.content,
              // text: data.title, // 文案
              // country: numbers, // 推送国家
              // userType: data.userType, // 用户身份
              // popupTime: data.popupTime, // 推动时间
              // beginTime: data.beginTime, // 推送开始时间
              // endTime: data.endTime, // 推送结束时间
              // jumpUrl: data.jumpUrl,
              // banner: data.banner
            }
          );
        } else {
          this.$notify.warning({
            title: "提示",
            message: "获取失败"
          });
        }
      } catch (error) {
        console.log("error", error);
      }
    },
    p(s) {
      return s < 10 ? "0" + s : s;
    },
    changeTimer(v) {
      //  格式化时间
      const d1 = new Date(v[0]);
      const d2 = new Date(v[1]);
      const resDate =
        d1.getFullYear() +
        "-" +
        this.p(d1.getMonth() + 1) +
        "-" +
        this.p(d1.getDate());
      const eDate =
        d2.getFullYear() +
        "-" +
        this.p(d2.getMonth() + 1) +
        "-" +
        this.p(d2.getDate());
      this.startTime = resDate;
      this.endTime = eDate;
      console.log(resDate, eDate);
    },
    getDate(str) {
      var date = new Date(str);
      var hours = String(date.getHours()).padStart(2, "0");
      var minutes = String(date.getMinutes()).padStart(2, "0");
      var seconds = String(date.getSeconds()).padStart(2, "0");
      var time = hours + ":" + minutes + ":" + seconds;
      return time;
    },
    checkFn(text, msg) {
      if (!text) {
        this.$notify.warning({
          title: "提示",
          message: msg
        });
        return false;
      } else {
        return true;
      }
    },
    // 上传图片校验
    beforeRemove(file, fileList) {
      console.log(fileList);
      var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      const extension =
        testmsg === "jpg" ||
        testmsg === "JPG" ||
        testmsg === "png" ||
        testmsg === "PNG";
      const isLt50M = file.size / 1024 / 1024 < 10;
      if (!extension) {
        this.$message({
          message: "上传文件只能是jpg或者png格式!",
          type: "error"
        });
        return false; //必须加上return false; 才能阻止
      }
      console.log(file);
      if (!isLt50M) {
        this.$message({
          message: "上传文件大小不能超过 10MB!",
          type: "error"
        });
        return false;
      }
      return extension && isLt50M;
    },

    // 上传封面
    uploadCover(params) {
      const file = params.file;
      console.log(params, file, "888");
      var formdata = new FormData();
      formdata.append("file", file);
      getUploadMh(formdata).then(res => {
        console.log(res);
        this.formData.banner = res.data.url;
      });
    },
    checkFnArr(text, msg) {
      if (text.length < 1) {
        this.$notify.warning({
          title: "提示",
          message: msg
        });
        return false;
      } else {
        return true;
      }
    },
    async pushFn() {
      //  date1: "",
      // date2: "",
      // date3: "",
      // date4: "",
      // date5: "",
      console.log("date1", this.getDate(this.date1));
      let dates = [];
      if (this.date1) {
        dates.push(this.date1);
      }
      if (this.date2) {
        dates.push(this.date2);
      }
      if (this.date3) {
        dates.push(this.date3);
      }
      if (this.date4) {
        dates.push(this.date4);
      }
      if (this.date5) {
        dates.push(this.date5);
      }
      if (!this.checkFn(this.formData.clientId, "请选择客户端类型")) {
        return;
      }
      if (!this.checkFn(this.formData.title, "请输入消息标题")) {
        return;
      }

      if (!this.checkFn(this.formData.content, "请输入消息正文")) {
        return;
      }
      if (!this.checkFn(this.formData.banner, "请输入配图")) {
        return;
      }
      if (!this.checkFnArr(this.formData.text, "请输入行动按钮文案")) {
        return;
      }
      if (!this.checkFnArr(this.formData.country, "请选择推送国家")) {
        return;
      }
      if (!this.checkFn(this.formData.userType, "请选择用户身份")) {
        return;
      }
      if (!this.checkFn(this.times, "请选择推送时间")) {
        return;
      }
      if (!this.checkFnArr(dates, "请选择每日弹出时间点")) {
        return;
      }

      if (!this.checkFn(this.formData.jumpUrl, "请输入网址")) {
        return;
      }
      var pattern = /^https?:\/\//;
      if (!pattern.test(this.formData.jumpUrl)) {
        this.$notify.warning({
          title: "提示",
          message: "请输出正确的网址"
        });
        return;
      }
      this.formData.beginTime = this.startTime;
      this.formData.endTime = this.endTime;
      console.log("this.formData", this.formData);

      // 将字符串转换为日期对象
      dates = dates.map(dateStr => new Date(dateStr));

      // 对日期进行排序
      dates.sort((a, b) => a - b);

      // 将排序后的日期转换为字符串
      let sortedDates = dates.map(date => date.toString());

      sortedDates.join(", ");
      // sortedDates.forEach((res)=>{
      //   return this.getDate(res)
      // })
      let sortedDatesTimes = sortedDates.map(date => {
        let parts = date.split(" ");
        return parts[4];
      });
      this.formData.popupTime = sortedDatesTimes;

      console.log("sortedDatesTimes", sortedDatesTimes);
      this.loading = true;
      try {
        const res = await pcUpdate(this.formData);
        if (res.code == 0) {
          this.$message({
            type: "success",
            message:
              this.$route.name == "pcDesktopAdd" ? "添加成功!" : "修改成功"
          });
          setTimeout(() => {
            this.$router.push({ name: "pcDesktop" });
          }, 1000);
        } else {
          this.$notify.warning({
            title: "提示",
            message: res.message
          });
        }
        this.loading = false;
        console.log("res", res);
      } catch (error) {
        this.loading = false;
        console.log("error", error);
      }
    },
    countryChange(e) {
      // 全球单选，其他多选
      const eHasGlobal = e.filter(res => {
        // 新选的是否包含全球
        return res == "AnyCountry";
      });
      const oddHasGlobal = this.countryTag.filter(res => {
        // 旧选的是否包含全球
        return res == "AnyCountry";
      });

      if (oddHasGlobal.length > 0 && e.length > 1) {
        console.log(11111);
        this.formData.country = e.filter(res => {
          // 新选的是否包含全球
          return res != "AnyCountry";
        });
      }
      if (oddHasGlobal.length == 0 && eHasGlobal.length > 0) {
        this.formData.country = ["AnyCountry"];
      }
      this.countryTag = this.formData.country;
    },
    channelArray() {
      clientConfig().then(res => {
        if (res.code == 0) {
          this.channelDatas = res.data;
          this.channelDatas.unshift({
            description: "全部",
            clientId: ""
          });
        }
        console.log("res", res);
      });
    }
  }
};
</script>

<style lang='scss'>
.pcadd {
  .container {
    position: relative;
  }
  .file {
    margin-top: 6px;
    position: relative;
    display: inline-block;
    background: #d0eeff;
    border: 1px solid #99d3f5;
    border-radius: 4px;
    padding: 4px 12px;
    overflow: hidden;
    color: #1e88c7;
    text-decoration: none;
    text-indent: 0;
    line-height: 20px;
  }
  .file input {
    position: absolute;
    font-size: 100px;
    right: 0;
    top: 0;
    opacity: 0;
  }
  .file:hover {
    background: #aadffd;
    border-color: #78c3f3;
    color: #004974;
    text-decoration: none;
  }
  .handle-box {
    margin-bottom: 20px;
  }

  .handle-select {
    width: 180px;
  }

  .handle-input {
    width: 300px;
    display: inline-block;
  }
  .table {
    width: 100%;
    font-size: 14px;
  }
  .red {
    color: #ff0000;
  }
  .mr10 {
    margin-right: 10px;
  }
  .mr20 {
    margin-right: 20px;
  }
  .table-td-thumb {
    display: block;
    margin: auto;
    width: 40px;
    height: 40px;
  }
  .w80 {
    width: 98px;
  }
  .ml10 {
    margin-left: 10px;
  }
  .btns {
    margin-bottom: 10px;
  }
  .check {
    display: flex;
  }
}
.up-btn ::v-deep .el-upload {
  width: 98px;
  height: 40px;
  border: 0 !important;
  background-color: #409eff;
  line-height: 20px;
}
.up-btn >>> .el-button--small {
  width: 80px;
  height: 32px;
  border: 0 !important;
  background-color: #409eff;
}
.pre {
  width: 100px;
  height: 100px;
}
.up-btn .el-upload--text {
  border: 0;
  width: 100px;
  height: 40px;
}
.view {
  position: absolute;
  width: 410px;
  height: 394px;
  background: white;
  border: 1px solid #e0e0e0;
  right: -1px;
  top: -1px;
  .title {
    font-size: 12px;
    font-weight: 400;
    color: rgba(29, 33, 41, 1);
    display: flex;
    align-items: center;
    position: relative;
    .img {
      width: 18px;
      height: 18px;
      margin: 0 6px 0 10px;
    }
    .el-icon-close {
      position: absolute;
      color: rgba(78, 89, 105, 1);
      right: 10px;
      font-size: 20px;
    }
  }
  .banner {
    width: 410px;
    height: 190px;
  }
  .p1 {
    font-size: 18px;
    font-weight: 600;
    color: rgba(29, 33, 41, 1);
    padding: 0 16px;
    margin-top: 16px;
  }
  .p2 {
    margin-top: 6px;
    font-size: 14px;
    font-weight: 400;
    padding: 0 16px;

    color: rgba(78, 89, 105, 1);
  }
  .b {
    width: 410px;
    height: 48px;
    line-height: 48px;
    background: rgba(242, 242, 242, 1);
    font-size: 14px;
    color: rgba(22, 93, 255, 1);
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0 16px;
  }
}
.ell {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ell2 {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden; //溢出内容隐藏
  text-overflow: ellipsis; //文本溢出部分用省略号表示
  display: -webkit-box; //特别显示模式
  -webkit-line-clamp: 2; //行数
  line-clamp: 2;
  -webkit-box-orient: vertical; //盒子中内容竖直排列
}
</style>
